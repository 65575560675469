import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Chip,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { CheckCircle, Cancel, HourglassEmpty, DoneAll } from "@mui/icons-material";
import moment from "moment";
import { GridColDef } from '@mui/x-data-grid';
import "./CustomScrollbar.css";

interface DataTableProps {
    columns: GridColDef[];
    rows: any[];
    filterText: string;
  }

  const ReportingTable: React.FC<DataTableProps> = ({ columns, rows, filterText }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const filteredRows = rows.filter(row =>
      Object.values(row).some(value => value?.toString().toLowerCase().includes(filterText.toLowerCase()))
    );

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
        
          <>
            <TableContainer
            component={Paper}
            className="table-container"
            style={{
              height: 400,
              border: `3px solid #f0f4f8`,
              overflowX: "auto",
            }}>
              <Table  sx={{ minWidth: 1150, width: "100%" }}
          stickyHeader
          aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell 
                      style={{
                        backgroundColor: "#f0f4f8",
                        width: 
                        column.width
                          ? column.width
                          : i === columns.length - 1
                          ? "100%"
                          : "auto",
                          textOverflow: "ellipsis",
                      }}
                      key={column.field}>{column.headerName}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: '#fbfcfe' }}>
    {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
      <TableRow key={row.id}>
        {columns.map((column, i) => (
          <TableCell 
                          style={{
                            width: 
                          column?.width
                              ? column.width
                              : i === columns.length - 1
                              ? "50%"
                              : "auto",
                            //whiteSpace: "nowrap",
                            fontSize: 12,
                            backgroundColor: '#fbfcfe',
                            color: Boolean(column?.field === "total_reports")
                              ? '#478CCF'
                              : "inherit",
                              // Ellipsis for long text
    textOverflow: (column.field === 'question_text' || column.field === 'reported_contents') ? "ellipsis" : "inherit",
    whiteSpace: (column.field === 'question_text' || column.field === 'reported_contents') ? "nowrap" : "inherit",
    overflow: (column.field === 'question_text' || column.field === 'reported_contents') ? "hidden" : "inherit",
    maxWidth: (column.field === 'question_text' || column.field === 'reported_contents') ? 150 : "inherit", // Limit the width of the column
                            // textDecoration: Boolean(column?.type === "number")
                            //   ? "underline"
                            //   : "inherit",
                            fontWeight: Boolean(column?.type === "number")
                              ? 500
                              : "inherit",
                          }}
                          key={column.field}>
            {
     column.renderCell ? column.renderCell(row) : row[column.field]}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>

              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
    );
  };
export default ReportingTable;
