import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Tabs, Tab, Box, TextField, Stack, Divider, Button, CircularProgress, Chip, Tooltip, FormHelperText, Grid, InputAdornment } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { makeStyles, Paper } from '@material-ui/core';
import ReportDialog from './ReportDialog';
import AssignDialog from './AssignDialog';
import EditScreen from './EditScreen';
import { formCompProps } from '../../Components/FormComponent';
import { allApis } from '../../request/config';
import { ObjectValues ,inputProp} from '../login';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { fetchReportData, clearReportData, clearListData, fetchReportedQuestions, fetchStaffList } from '../../store/slices/contentFeedback/contentFeedbackSlice';
import { useAppDispatch, useAppSelecter } from '../../hooks/storeHooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { fetchTagsData } from '../../store/slices/exams/thunks/fetchContentData';
import ReportingTable from './ReportingTable';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface quesformProp extends formCompProps {
  getApi: any;
}

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#0D47A1', // Set your desired color here
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}



const useStyles = makeStyles({
  tabsIndicator: {
    backgroundColor: '#FF5722', 
  },
  dataGrid: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px', 
      flex: 1,
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      justifyContent: 'space-between',
      paddingRight: '8px', 
      flex: 1,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'visible',
      whiteSpace: 'nowrap',
      textOverflow: 'clip',
    },
    '& .MuiDataGrid-sortIcon': {
      marginLeft: '4px', 
    },
    '& .MuiDataGrid-menuIconButton': {
      marginRight: '4px', 
    },
    '& .MuiTabs-indicator': {
    backgroundColor: '#FF5722', 
  },
  },
});



const ContentFeedback: React.FC = () => {
  const [value, setValue] = useState(0);
  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editScreenId, setEditScreenId] = useState<number | null>(null);
  const [reviewStatus, setReviewStatus] = useState("pending"); // Initial status
  const [selectedStaff, setSelectedStaff] = useState<any | null>(null);
  const [editData, setEditData] = useState<any>(null);
 const [showLoading, setShowLoading] = useState(true);

  const dispatch = useAppDispatch();
  const { reportedQuestions,staffList,reportData, questionEditData,reportLoading, loading, error } = useSelector(
    (state: RootState) => state.contentFeedback
  );

  const examsData = useAppSelecter(
    (state: ObjectValues) => state.exams
  );
  const tags_list = examsData.tags_list ?? [];
  const columnWidth = 150;
 const navigate = useNavigate();
  

useEffect(() => {
  const previousUrl = window.location.href;
  localStorage.setItem('previousUrl', previousUrl);
}, []);

useEffect(() => {
  // Fetch reported questions when the component mounts or tab changes
  dispatch(fetchTagsData());
  dispatch(fetchReportedQuestions(reviewStatus));
}, [dispatch,reviewStatus]);

useEffect(() => {
  dispatch(fetchStaffList());
}, [dispatch]);

const handleStaffChange = (event: any, newValue: any) => {
  setSelectedStaff(newValue);
};

  const ImageUpload = () => {
    return {
      label: "Genererate Image URL",
      placeholder: "Choose image",
      value: "",
      error: false,
      helperText: " c",
      endAdornment: "",
      fieldType: "image",
      api: "logo_url",
      not_required: true,
    };
  };

  const inputArray = (content: any) => {
    const row: any = content.selected_row;
    return [
      ImageUpload(),
      
      {
        label: "Question",
        fullWidth: true,
        placeholder: "Enter Question",
        value: "",
        error: false,
        helperText: "",
        endAdornment: "",
        fieldType: "cktextEditor",
        api: "question",
      },
      {
        label: "Option A",
        placeholder: "Enter Option A",
        value: "",
        error: false,
        helperText: "",
        endAdornment: "",
        fieldType: "cktextEditor",
        dataType: "option",
        api: "option1",
      },
      {
        label: "Option B",
        placeholder: "Enter Option B",
        value: "",
        error: false,
        helperText: "",
        endAdornment: "",
        dataType: "option",
        fieldType: "cktextEditor",
        api: "option2",
      },
      {
        label: "Option C",
        placeholder: "Enter Option C",
        value: "",
        error: false,
        helperText: "",
        endAdornment: "",
        dataType: "option",
        fieldType: "cktextEditor",
        api: "option3",
      },
      {
        label: "Option D",
        placeholder: "Enter Option D",
        value: "",
        error: false,
        helperText: "",
        endAdornment: "",
        dataType: "option",
        fieldType: "cktextEditor",
        api: "option4",
      },
      {
        label: "Explanation",
        fullWidth: true,
        placeholder: "Enter Explanation",
        value: "",
        error: false,
        helperText: "",
        endAdornment: "",
        fieldType: "cktextEditor",
        api: "explanation",
        not_required: true,
      },
      {
        label: "Question Tags",
         list: tags_list ?? [],
        multiple: true,
        listLabel: "name",
        error: false,
        helperText: " ",
        endAdornment: "",
        fieldType: "autocomplete",
        not_required: true,
        freeSolo: {
          dialog: true,
        },
        api: "tag",
      },
    ] as inputProp[];
  }

  const formProps = {
    // successMessage: (res: ObjectValues) => "data updated successfully",
    inputSteps: [],
      inputFormArray: inputArray({}),
    header: "question report",
    editData: questionEditData,
    api: {
        edit: null,
        edit_method: "PATCH",
        create: allApis.create,
        create2: allApis.create2,
    },
    // onSubmit: () => {
    //     // if (dockedCard.title === allModulesInMockTest[6] && fetchAgain.all_cluster !== true) {
    //     //     dispatch(setFetchAgain({ all_cluster: true }))
    //     // }
    // },
    // onClear: () => {
    // }
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#478CCF",
  },
}));

  const columns1: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 70,flex: 1 },
    {field: "question", headerName: "Question ID", width: 100 },
    { field: 'question_text', headerName: 'Question Text', width: 150, flex: 1 ,
      renderCell: (row: any) => {
        // console.log(row)
        return (
        <BootstrapTooltip placement="top-start" title={row.question_text}>
          <span>{row.question_text}</span>
        </BootstrapTooltip>
      );
    }
    },
    { field: 'reported_contents', headerName: 'Reported Contents',width: 150, flex: 1 },
    {
      field: 'total_reports',
      headerName: 'Reported Count',
      type: 'number',
      width: 150,
      flex: 1,
      align: 'left', headerAlign: 'left',
      renderCell: (row: any) => {
        //  console.log(row)
        return (
        <div 
          style={{ 
            display: 'inline-flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            textDecoration: 'underline', // Underline the text
          }}
          onClick={() => {
            setShowLoading(true);
             handleShowReport(row.id)} // Click event
          }
        >
           {row?.total_reports}
        </div>
        );
      }
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 130,
      flex: 1,
      renderCell: (row: any) => (
        <Button size="small" style={{ backgroundColor: "#0D47A1",
          color: "#FFFFFF",textTransform: "none", fontSize: "12.8px", borderRadius: "5px", padding: "5px" }}
          onClick={() => handleAssign(row)}>Assign</Button>
      ),
    },
  ];

  const columns2: GridColDef[] = [
    {field: "question", headerName: "Question ID", width: 100  },
    { field: 'question_text', headerName: 'Question Text', width: 150, flex: 1,
      renderCell: (row: any) => {
        // console.log(row)
        return (
        <BootstrapTooltip placement="top-start" title={row.question_text}>
          <span>{row.question_text}</span>
        </BootstrapTooltip>
      );
    }
     },
    { field: 'reported_contents', headerName: 'Reported Contents', width: 150, flex: 1 },
    { field: 'total_reports', headerName: 'Reported count', width: 150, flex: 1,
      renderCell: (row: any) => (
        <div 
          style={{ 
            display: 'inline-flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            textDecoration: 'underline', // Underline the text
          }}
          onClick={() => {
            setShowLoading(true);
            handleShowReport(row.id)} // Click event
          } // Click event
        >
          {row?.total_reports}
        </div>
      ),
     },
    {
      field: 'action',
      headerName: 'Action',
      type: 'number',
      width: 130,
      flex: 1,
      align: 'left', headerAlign: 'left',
      renderCell: (row: any) => {
        // console.log(params)
        return (
        <Button size="small" style={{ backgroundColor: "#0D47A1", color: "white", textTransform: "none", fontSize: "12.8px", borderRadius: "5px", padding: "5px" }}
          onClick={() => handleReview(row?.question, row)}>Review</Button>
        );    
        }
    },
  ];

  const columns3: GridColDef[] = [
    {field: "question", headerName: "Question ID", width: 100 },
    { field: 'question_text', headerName: 'Question Text', width: 150, flex: 1,
      renderCell: (row: any) => {
        // console.log(row)
        return (
        <BootstrapTooltip placement="top-start" title={row.question_text}>
          <span>{row.question_text}</span>
        </BootstrapTooltip>
      );
    }
     },
    { field: 'action_taken', headerName: 'Action Taken', width: 150, flex: 1 },
    { field: 'reported_contents', headerName: 'Reported Contents', width: 150, flex: 1 },
    { field: 'total_reports', headerName: 'Reported Count', width: 150, flex: 1,
      renderCell: (row: any) => (
        <div 
          style={{ 
            display: 'inline-flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            textDecoration: 'underline', // Underline the text
          }}
          onClick={() => {
            setShowLoading(true);
            handleShowReport(row.id)} // Click event
          } // Click event
        >
          {row?.total_reports}
        </div>
      ),
     },
    { field: 'reviewed_by', headerName: 'Reviewed By', width: 150, flex: 1 },
    { field: 'reviewed_at', headerName: 'Reviewed On', width: 150, flex: 1,
      renderCell: (row: any) => (
        row.reviewed_at ? moment(row.reviewed_at).format('DD-MM-YYYY hh:mm') : ''
      ),},
    { field: 'validator_remark', headerName: 'Reviewed Remark', width: 150, flex: 1 },
  ];

  const handleShowReport = (reportId: string) => {
    setOpenReportDialog(true);
    dispatch(clearReportData());
    dispatch(fetchReportData(reportId));
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  };

  const handleCloseDialog = () => {
    setOpenReportDialog(false);
  };

  // Update the review status based on the selected tab
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(clearListData()); // Clear the list data
  dispatch(clearReportData()); // Clear the report data
    setValue(newValue);
    if (newValue === 0) {
      setReviewStatus("pending");
    } else if (newValue === 1) {
      setReviewStatus("under_review");
    } else if (newValue === 2) {
      setReviewStatus("reviewed");
    }
  };


  const handleAssign = (row: any) => {
    setSelectedRow(row);
    setOpenAssignDialog(true);
  };

  const handleCloseAssignDialog = () => {
    handleStaffChange(null, null);
    setOpenAssignDialog(false);
  };

  const handleConfirmAssign = (assignee: any) => {
  };

  useEffect(() => {
    if (editScreenId !== null) {
      console.log("editScreenId", editScreenId);
      navigate('/contentFeedback/editScreen', {
        state: { ...formProps, editScreenId, row: selectedRow },
      });
    }
  }, [editScreenId, selectedRow, navigate]);

  const handleReview = (id: number,row: any) => {
    setEditScreenId(id);
    setSelectedRow(row);
  };

  const handleCloseEditScreen = () => {
    setEditScreenId(null);
  };

  function stripHtmlTags(html: any) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const sanitizedReportedQuestions = reportedQuestions.map((question) => ({
    ...question,
    question_text: stripHtmlTags(question.question_text),
  }));

  function DataTable({ columns, rows, filterText }: { columns: GridColDef[], rows: any[], filterText: string }) {
    const classes = useStyles();
    const filteredRows = rows.filter(row => Object.values(row).some(value => value?.toString().toLowerCase().includes(filterText.toLowerCase())));
  
    return (
      <div style={{ height: 200, width: '100%' }}>
        {loading ? 
        <Box display="flex" justifyContent="center" alignItems="center" height="350px">
            <CircularProgress />
          </Box> :
        <ReportingTable
          rows={filteredRows}
          // className={classes.dataGrid}
          columns={columns}
          filterText=''
          // initialState={{
          //   pagination: {
          //     paginationModel: { page: 0, pageSize: 25 },
          //   },
          // }}
          // pageSizeOptions={[5, 10]}
        // checkboxSelection
        />
         }
      </div>
    );
  }
  
  return (
    <Paper style={{ width: '99%', height: "87vh", borderRadius: "15px", color:"" }}>
        <>
          <div style={{ margin: "30px", fontSize: "17.6px",color: "#0D47A1" }}>List of Reported Questions</div>
          <Divider variant='fullWidth' />
          <Box sx={{ width: '100%',bgcolor: 'background.paper' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
              <CustomTabs value={value} onChange={handleChange} style={{ marginLeft: "30px" }} aria-label="basic tabs example">
                <Tab label="Pending" {...a11yProps(0)} style={{color: value === 0 ? '#0D47A1' : '#424242',fontWeight: value === 0 ? 'bold' : 'normal'}} />
                <Tab label="Under Review" {...a11yProps(1)} style={{color: value === 1 ? '#0D47A1' : '#424242',fontWeight: value === 1 ? 'bold' : 'normal'}}/>
                <Tab label="Reviewed" {...a11yProps(2)} style={{color: value === 2 ? '#0D47A1' : '#424242',fontWeight: value === 2 ? 'bold' : 'normal'}} />
              </CustomTabs>
            </Box>
            <TabPanel value={value} index={0}>
        {/* <Grid item xs={6} md={6} lg={5}>
                <FormHelperText style={{color: "#333",fontSize: 13,fontWeight: 600}}>
                  Search By Question ID
                </FormHelperText>
                <TextField
                  size="small"
                  placeholder="Search"
                   value={filterText1}
                   onChange={(e) => setFilterText1(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ transform: "scale(0.8)" }}
                      >
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          transform: "scale(0.8)",
                          // display: !Boolean(filter.searchText) && "none",
                        }}
                        onClick={() => {
                          // setFilter((prevValue) => {
                          //   return {
                          //     ...prevValue,
                          //     searchText: "",
                          //   };
                          // });
                        }}
                      >
                        <CloseIcon />
                      </InputAdornment>
                    ),
                    style: { fontSize: 14, backgroundColor: "#fbfcfe" },
                  }}
                  fullWidth
                />
              </Grid> */}
              <DataTable columns={columns1} rows={reportedQuestions} filterText={filterText1} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/* <TextField
          label="Filter"
          variant="outlined"
          fullWidth
          margin="normal"
          value={filterText2}
          onChange={(e) => setFilterText2(e.target.value)}
        /> */}
              <DataTable columns={columns2} rows={reportedQuestions} filterText={filterText2} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/* <TextField
          label="Filter"
          variant="outlined"
          fullWidth
          margin="normal"
          value={filterText3}
          onChange={(e) => setFilterText3(e.target.value)}
        /> */}
              <DataTable columns={columns3} rows={sanitizedReportedQuestions} filterText={filterText3} />
            </TabPanel>
            <ReportDialog reportLoading={reportLoading} open={openReportDialog} onClose={handleCloseDialog} reportData={reportData} />
            <AssignDialog staffList={staffList}
             selectedStaff={selectedStaff}
            handleStaffChange={handleStaffChange}
            row={selectedRow}
            open={openAssignDialog}
             onClose={handleCloseAssignDialog}
              onConfirm={handleConfirmAssign}
                />
          </Box>
        
        </>

      
    </Paper>
  );
};

export default ContentFeedback;
